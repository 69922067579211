import '../styles/globals.css'
import Head from 'next/head'
import Script from 'next/script'
import useGtag from 'utils/useGtag'
import { init } from 'utils/sentry'
import type React from 'react'

init()

const APP_NAME = 'PicGrid'
const APP_DESCRIPTION = 'Grid Web Editor'
const APP_URL = `https://${
  (process.env.NEXT_PUBLIC_VERCEL_ENV !== 'production' && process.env.NEXT_PUBLIC_VERCEL_URL) ||
  'picgrid.picc.co'
}`
const IMAGE = '/social-image.png'
const TW_IMAGE = `${APP_URL}/social-image.png`

function MyApp({ Component, pageProps }: any) {
  useGtag()

  return (
    <>
      <Head>
        <meta name="application-name" content={APP_NAME} />
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta name="apple-mobile-web-app-status-bar-style" content="black" />
        <meta name="apple-mobile-web-app-title" content={APP_NAME} />
        <meta name="description" content={APP_DESCRIPTION} />
        <meta name="format-detection" content="telephone=no" />
        <meta name="mobile-web-app-capable" content="yes" />
        <meta name="theme-color" content="#fafafa" />

        <meta name="twitter:url" content={APP_URL} />
        <meta name="twitter:title" content={APP_NAME} />
        <meta name="twitter:description" content={APP_DESCRIPTION} />
        <meta name="twitter:card" content="summary_large_image" />
        {/* <meta name="twitter:creator" content="@tldraw" />
        <meta name="twitter:site" content="@tldraw" /> */}
        <meta name="twitter:image" content={TW_IMAGE} />

        <meta property="og:type" content="website" />
        <meta property="og:title" content={APP_NAME} />
        <meta property="og:description" content={APP_DESCRIPTION} />
        <meta property="og:site_name" content={APP_NAME} />
        <meta property="og:url" content={APP_URL} />
        <meta property="og:image" content={IMAGE} />

        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, minimum-scale=1.0, user-scalable=no"
        />

        <link rel="manifest" href="/manifest.json" />
        <link rel="shortcut icon" href="/favicon.png" />
        <link rel="apple-touch-icon" sizes="128x128" href="/favicon.png" />

        <title>{APP_NAME}</title>
      </Head>
      {/* Hotjar Tracking Code for https://picgrid.picc.co */}
      <Script
        id="hotjar"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `(function(h,o,t,j,a,r){
              h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
              h._hjSettings={hjid:3084400,hjsv:6};
              a=o.getElementsByTagName('head')[0];
              r=o.createElement('script');r.async=1;
              r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
              a.appendChild(r);
            })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`,
        }}
      />
      <Component {...pageProps} />
    </>
  )
}

export default MyApp
